import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Link} from "../utils";

const styles = StyleSheet.create({
  textH1: {fontSize: 40},
  textH3: {fontSize: 20, fontWeight: 'bold'},
  textTitle: {fontSize: 20, fontWeight: 'bold', marginTop: 40},
  text: {fontSize: 15, marginTop: 20, alignSelf: "flex-start"},
  textBold: {fontWeight: 'bold'}
});

export default class PrivacyPage extends React.Component {
  render() {
    return (
      <>
        <View style={{flex: 1, marginTop: 50, flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <Text style={[styles.textH1, styles.textBold]}>Privacy Policy</Text>
          <Text style={[styles.textBold, {marginTop: 5}]}>last updated: Oct 30, 2021</Text>
        </View>
        <Text style={[{marginTop: 20}, styles.textH3]}>
          AT OKA CAMERA, YOUR PRIVACY IS A TOP PRIORITY. YOUR PRIVACY IS AT THE CORE OF THE WAY WE DESIGN AND BUILD THE
          SERVICES AND PRODUCTS YOU KNOW AND LOVE, SO THAT YOU CAN FULLY TRUST THEM AND FOCUS ON BUILDING MEANINGFUL
          CONNECTIONS. WE APPRECIATE THAT YOU PUT YOUR TRUST
          IN US WHEN YOU PROVIDE US WITH YOUR INFORMATION
          AND WE DO NOT TAKE THIS LIGHTLY.
        </Text>

        <Text style={styles.textTitle}>
          BRATTE LTD. (“WE,” “OUR,” “US”) IS COMMITTED
          TO PROTECTING YOUR PRIVACY
        </Text>
        <Text style={styles.text}>
          This privacy policy (“Privacy Policy”) sets out how we handle the information we collect directly from you
          when you provide it to us or automatically when you use the Service, including information obtained through
          third parties. In particular, this Privacy Policy sets out how we will handle any personal information that is
          disclosed, collected or otherwise handled in connection with the Service. This policy applies only to
          information we collect in the App or the Website, and in email, text and other electronic communications sent
          through or in connection with the App or the Website. This policy DOES NOT apply to information that we
          collect offline or on any other websites, including website you may access through the App or the Website, or
          the information you provide to or is collected by any third parties.
        </Text>
        <Text style={styles.textTitle}>
          ACCEPTANCE OF THE PRIVACY POLICY; CHANGES
        </Text>
        <Text style={styles.text}>
          This Privacy Policy is incorporated into and is subject to our Terms and Conditions (the “Terms”). Capitalized
          terms used but not defined herein are defined in the Terms. If you do not agree with the Terms or this Privacy
          Policy, please do not provide us with any information and do not download and install our mobile application
          (“App”) or our Services. You can find our Terms here: <Link
          href="https://okacam.com/terms">https://okacam.com/terms.html</Link>
          <br/><br/>
          This Privacy Policy may change from time to time and it is therefore important that you review it regularly.
          You can determine when the Privacy Policy was last revised by referring to the “Last Updated” legend at the
          top of the page. If the changes include material changes that affect your rights or obligations, we will
          notify you in advance of the changes by reasonable means, which could include notification through the Service
          or via email. If you continue to use the Service after the changes become effective, then you agree to the
          revised Privacy Policy.
        </Text>
        <Text style={styles.textTitle}>
          WHAT PERSONAL INFORMATION DO WE COLLECT?
        </Text>
        <Text style={styles.text}>
          Personal information means information or opinion about an individual, or an individual who is reasonably
          identifiable (whether the information or opinion is true or not, and whether the information or opinion is
          recorded in a material form). Personal Information includes any information that may be used, either alone or
          in combination with other information, to personally identify an individual, including, but not limited to, a
          first and last name, a personal profile, an email address or other contact information.
          <br/><br/>We may collect and use different kinds of information in the course of providing the Services which
          we have grouped together as follows:<br/><br/>

          <b>&#x2022; Contact information:</b> includes your name, age, phone number, email address and your physical
          address;<br/>
          <b>&#x2022; Transaction information:</b> includes details about subscriptions and in-app purchases;<br/>
          <b>&#x2022; Profile information</b> includes your User Content;<br/>
          <b>&#x2022; Sensitive information:</b> includes information regarding your racial or ethnic origin, religious
          and philosophical beliefs, political or sexual orientation information, and other sensitive information;<br/>
          <b>&#x2022; Technical information:</b> includes username and passwords, internet protocol (IP) address, your
          login data, browser type, time zone setting and location, third party access tokens and other technology on
          the devices you use to access the Service;<br/>
          <b>&#x2022; Usage information:</b> includes information about how you use the Service, the types of Content
          you interact with, the frequency and duration of time that you use the App’s products and services; and any
          other platforms from which you link to us;<br/>
          <b>&#x2022; Content information:</b> this includes the content of communications, people you are connected to
          and how you interact with them, and your preferences and choices;<br/>
          <b>&#x2022; Marketing information:</b> includes your preferences in receiving marketing communications from
          us; and any other personal information that you provide to us either directly or indirectly when using the
          Service.<br/>
        </Text>
        {/*<Text style={styles.textTitle}>*/}
        {/*  YOUR CALIFORNIA PRIVACY RIGHTS*/}
        {/*</Text>*/}
        {/*<Text style={styles.text}>*/}
        {/*  If you are a California resident, California law may provide you with additional rights regarding our use of*/}
        {/*  your personal information. To learn more about your California privacy rights, visit <Link*/}
        {/*  href="http://okacam.com/ccpa">CCPA Privacy Notice for California Residents.</Link><br/><br/>*/}

        {/*  California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our SERVICE that are*/}
        {/*  California residents to request certain information regarding our disclosure of personal information to third*/}
        {/*  parties for their direct marketing purposes. To make such a request, please contact our Privacy Officer at*/}
        {/*  hello@okacam.com*/}
        {/*</Text>*/}
        <Text style={styles.textTitle}>
          CHILDREN UNDER THE AGE OF 13
        </Text>
        <Text style={styles.text}>
          The Service is not intended for children under 13 years of age, and we do not knowingly collect personal
          information from children under 13. If we learn we have collected or received personal information from a
          child under 13 without verification of parental consent, we will delete that information. If you believe we
          might have any information from or about a child under 13, please contact us at hello@okacam.com<br/><br/>

          {/*California residents under 16 years of age may have additional rights regarding the collection and sale of*/}
          {/*their personal information. Please see “Your California Privacy Rights” above for more information.*/}
        </Text>
        <Text style={styles.textTitle}>
          HOW DO WE COLLECT YOUR PERSONAL INFORMATION?
        </Text>
        <Text style={styles.text}>
          We will collect personal information directly from you wherever it is reasonable and practical to do so. We
          may collect personal information from you via:<br/>
          &#x2022; your use and access of the Service, including during your registration as a User and when you post or
          bid on Offers;<br/>
          &#x2022; your response to any surveys or marketing materials we send out;<br/>
          &#x2022; information and recommendations uploaded to the Service by yourself or by other Users; and <br/>
          &#x2022; interactions between yourself, other users of the Service, and our staff.<br/>
        </Text>
        <Text style={styles.text}>
          We may also collect your personal information from third parties including:<br/>
          &#x2022; third parties who provide services to us that help us provide our services to you;<br/>
          &#x2022; through other third parties using the Service that provide information about you; and<br/>
          &#x2022; other third parties who provide services via the Service.<br/>
        </Text>
        <Text style={styles.textTitle}>
          WHY DO WE COLLECT YOUR PERSONAL INFORMATION?
        </Text>
        <Text style={styles.text}>
          We provide a camera app which helps to take better photos and videos. To
          achieve this goal, we collect your personal information:<br/>
          &#x2022; to assess the performance of the Service and to improve the services we provide;<br/>
          &#x2022; to record your likes and preferences, for better photo and video suggestions and Service
          functionality;<br/>
          &#x2022; to process and respond to a complaint or request you may make;<br/>
          &#x2022; to contact you in relation to our Services, particularly if we believe there are any particular
          Offers you might be interested in; or<br/>
          &#x2022; for the provision of services or the administration of our business (including fulfilling and
          exercising our obligations and rights, exercising or defending legal claims and managing the relationship with
          you).<br/>
        </Text>
        <Text style={styles.text}>
          The legal basis for collecting and using personal information will depend on the specific circumstances in
          which it is collected. However, we will collect personal information from you where:<br/>
          &#x2022; you have given consent for our use of the personal information for one or more purposes;<br/>
          &#x2022; it is necessary for the performance of a contract to which you are a party;<br/>
          &#x2022; it is necessary for the legitimate interests pursued by us and those interests are balanced against
          your rights to information privacy (e.g., to communicate useful content updates); or<br/>
          &#x2022; we have legal obligations that we need to comply with, and are allowed to use your personal
          information in order to comply with such legal obligations.<br/>
        </Text>
        <Text style={styles.textTitle}>
          HOW DO WE USE YOUR PERSONAL INFORMATION?
        </Text>
        <Text style={styles.text}>
          We may use the personal information we collect from you to:<br/>
          &#x2022; enforce our Terms and this Privacy Policy;<br/>
          &#x2022; notify you about any changes in or to the Service or our services;<br/>
          &#x2022; fulfill our legal obligations and respond to any litigation or law enforcement/government
          inquiry;<br/>
          &#x2022; insure that the Service is presented to you in the most efficient manner for you and us;<br/>
          &#x2022; improve the design and performance of the Service;<br/>
          &#x2022; deliver to you communications, emails, newsletters and marketing materials from us and third parties
          as you request them or which we feel may interest you, where you have consented to be contacted for such
          purposes;<br/>
          &#x2022; allow you to participate in any interactive features of the Service, when you choose to do so;<br/>
          &#x2022; administer the Service and keep it safe and secure;<br/>
          &#x2022; facilitate internal operations, including research, statistics, troubleshooting, data analysis,
          testing, survey and similar purposes;<br/>
          &#x2022; share it with third party service providers (as we describe in more detail below);<br/>
          &#x2022; disclose as we believe to be necessary or appropriate: (a) under applicable law, including laws
          outside your country of residence; (b) to protect our operations or those of any of our affiliates; (c) to
          protect our rights, privacy, safety or property, or property that belongs to you or others; or (d) to allow us
          to pursue available remedies or limit the damages that we may sustain;<br/>
          &#x2022; evaluate or conduct a merger, divestiture, restructuring, reorganization, dissolution, or other sale
          or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or
          similar proceeding, in which personal information held by us about our users is among the assets
          transferred;<br/>
          &#x2022; fulfil any other purpose disclosed by us when you provide the information; or<br/>
          &#x2022; otherwise, with your consent.<br/>
          <br/>
          We will not collect additional categories of personal information or use the personal information we collected
          for materially different, unrelated, or incompatible purposes without providing you notice.
        </Text>
        <Text style={styles.textTitle}>
          WHO MAY WE DISCLOSE YOUR PERSONAL INFORMATION TO?
        </Text>
        <Text style={styles.text}>
          We do not sell, dispose, or otherwise transfer your personal information to third parties without your consent
          except as specifically set forth in this Privacy Policy. However, we may disclose your personal information to
          various third parties. These third parties may include:<br/>
          &#x2022; technology partners in connection with services that these third parties perform for us;<br/>
          &#x2022; our lawyers, accountants, professional advisors, and other third-party service providers;<br/>
          &#x2022; to our affiliates;<br/>
          &#x2022; to law enforcement authorities where we are required to do so by law or pursuant to a legal process,
          or when we believe disclosure is reasonably necessary or appropriate to prevent harm in connection to a
          suspected illegal activity; or<br/>
          &#x2022; to any entity in the event that we sell or transfer all or a portion of our business or assets.
          Should such a sale or transfer occur, we will use reasonable efforts to require that the transferee uses the
          personal information you have provided to us in a manner that is consistent with this Privacy
          Policy.<br/><br/>
          Personal information collected within the European Union may be transferred to and processed by third parties
          located in a country outside of the European Union. In such instances, we shall ensure that the transfer of
          such personal information is carried out in accordance with applicable privacy laws and, in particular, that
          appropriate contractual, technical, and organizational measures are in place.
          <br/><br/>
          Except as otherwise set forth in this Privacy Policy, we will not provide access to your personal information
          to third parties for any purpose.
        </Text>
        <Text style={styles.textTitle}>
          WHAT IF WE CAN’T COLLECT YOUR PERSONAL INFORMATION?
        </Text>
        <Text style={styles.text}>
          If you do not provide us with the persona l information we need, you will not be able to use the Service to
          place Offers or bid on Offers, and we may not be able to provide you with targeted content and relevant
          information.
        </Text>
        <Text style={styles.textTitle}>
          INFORMATION WE COLLECT THROUGH AUTOMATIC DATA COLLECTION
        </Text>
        <Text style={styles.text}>
          As you interact with the Service, we may use automatic data collection technologies to collect certain
          information about your equipment, browsing actions, and patterns, including:<br/>

          &#x2022; details of your visits to the Service, including the App, including traffic data, location data,
          logs, and other communication data and the resources that you access and use on the Service;<br/>
          &#x2022; information about your computer, mobile device, and internet connection, including your IP address,
          operating system, and browser type, device’s unique device identifier, mobile network information, and the
          device’s telephone number;<br/>
          &#x2022; real-time information about the location of your device; and<br/>
          &#x2022; metadata and other information associated with other files stored on your device, such as
          photographs, audio and video clips, personal contacts, and address book information.<br/>
          <br/>
          The information we collect automatically is only statistical data and does not include personal information,
          but we may maintain it or associate it with personal information we collect
          in other ways or receive from third parties. It helps us to improve the Service and to deliver a better and
          more personalized service, including by enabling us to:<br/>

          &#x2022; estimate our audience size and usage patterns;<br/>
          &#x2022; store information about your preferences, allowing us to customize the Service according to your
          individual interests;<br/>
          &#x2022; speed up your searches; and<br/>
          &#x2022; recognize you when you return to the Service.<br/>
          <br/>
          The technologies we use for this automatic data collection may include:<br/>

          &#x2022; <b>Cookies (or mobile cookies).</b> A cookie is a small file placed on your smartphone. You may
          refuse to accept mobile cookies by activating the appropriate setting on your smartphone. However, if you
          select this setting you may be unable to access certain parts of the Service. Unless you have adjusted your
          setting so that it will refuse cookies, our system will issue cookies when you are using the Service.<br/>
          &#x2022; <b>Flash Cookies.</b> Certain features of the Service may use local stored objects (or Flash cookies)
          to collect and store information about your preferences and navigation to, from, and on the Service. Flash
          cookies are not managed by the same browser settings as are used for browser cookies. For information about
          managing your privacy and security settings for Flash cookies, see “Choices About How We Use and Disclose Your
          Information” below.<br/>
          &#x2022; <b>Web Beacons.</b> Pages of the Service and our emails may contain small electronic files known as
          web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit us, for example,
          to count users who have visited those pages or opened an email and for other related statistics (for example,
          recording the popularity of certain content and verifying system and server integrity).<br/>
          <br/>
          We do not collect personal information automatically, but we may tie this information to personal information
          about you that we collect from other sources or you provide to us.
          <br/><br/>
          If you do not want us to collect this information from you, you may opt out at any time by contacting our
          Privacy Officer at hello@okacam.com. [Note, however, that opting out of the App’s collection of location
          information will disable its location-based features.] [We also may use these technologies to collect
          information about your activities over time and across third-party websites, apps, or other online services
          (behavioral tracking).]
        </Text>
        <Text style={styles.textTitle}>
          CHOICES ABOUT HOW WE USE AND DISCLOSE YOUR INFORMATION
        </Text>
        <Text style={styles.text}>
          We strive to provide you with choices regarding the personal information you provide to us. We have created
          mechanisms to provide you with the following control over your information:
          <br/>
          &#x2022; Tracking Technologies and Advertising. You can set your browser or smartphone settings to refuse all
          or some cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie
          settings, visit the Flash player settings page on Adobe's website. If you disable or refuse cookies, some
          parts of the Service may then be inaccessible or not function properly.<br/>
          &#x2022; Location Information. You can choose whether or not to allow the App to collect and use real-time
          information about your device’s location through the device’s privacy settings. If you block the use of
          location information, some parts of the Service may then be inaccessible or not function properly.<br/>
          &#x2022; Disclosure of Your Information for Third-Party Advertising. If you do not want us to share your
          personal information with unaffiliated or non-agent third parties for promotional purposes, you can opt out at
          any time by contacting our Privacy Officer at hello@okacam.com.<br/>
          &#x2022; Promotional Offers from Us. If you do not wish to have your contact information used by us to promote
          our own or third parties’ products or services, you can opt-out opt out of receiving electronic messages from
          us at any time by contacting our Privacy Officer via the contact details at the bottom of this Privacy Policy
          or by using the opt out mechanism included in our electronic marketing messages. If we have sent you a
          promotional email, you may send us a return email at hello@okacam.com, asking to be omitted from future email
          distributions.<br/>
          &#x2022; Targeted Advertising. If you do not want us to use information that we collect or that you provide to
          us to deliver advertisements according to our advertisers’ target-audience preferences, you can opt-out by
          contacting our Privacy Officer at hello@okacam.com or by using the opt out mechanism included in such
          advertisements.<br/>
          <br/>
          We do not control third parties’ collection or use of your information to serve interest-based advertising.
          However, these third parties may provide you with ways to choose not to have your information collected or
          used in this way. You can opt out of receiving targeted ads from members of the Network Advertising Initiative
          (“NAI”) on the NAI’s website.
          <br/>
          Nevada residents who wish to exercise their sale opt-out rights under Nevada Revised Statutes Chapter 603A may
          submit a request to our Privacy Officer at hello@okacam.com. However, please know we do not currently sell
          data triggering that statute’s opt-out requirements.
        </Text>
        <Text style={styles.textTitle}>
          DATA SECURITY
        </Text>
        <Text style={styles.text}>
          We primarily store your information in [the United States]. Some information may be stored elsewhere. You
          authorize us to do so. The personal information and other data we collect about you and your use of the
          Service may be maintained in servers we control and maintain or in third-party servers. The information you
          provide may be transferred or accessed by entities in many different countries as described elsewhere in this
          Privacy Policy. By submitting your information, you agree to the transfer, storage and processing of your data
          in accordance with this Privacy Policy.
          <br/>
          <br/>
          The personal information you provide to us is stored on servers that are located in secured facilities with
          restricted access, and protected by protocols and procedures designed to ensure reasonable security of such
          information. In addition, we restrict access to user personal information to those of our employees,
          independent contractors and agents who need to know this information in order to develop, operate and maintain
          the Service. They will only process your personal information on our instructions and they are subject to a
          duty of confidentiality. Although we take commercially reasonable measures to safeguard against unauthorized
          disclosures of your information, no e-commerce platform, application, website, electronic database or other
          format or system is completely secure and, for this reason, we cannot ensure or warrant the security of any
          information that you transmit to us. You can help protect your personal information by using a combination of
          letters, numbers and characters in your password, changing your password often, using a secure web browser,
          and signing off from the Service when you are finished with its use.
          <br/><br/>
          When we collect or transmit sensitive information, we use industry standard methods to protect that
          information.
          <br/><br/>
          We have also put in place procedures to deal with any suspected personal information breach and will notify
          you and any applicable regulator of a breach where we are legally required to do so.
        </Text>
        <Text style={styles.textTitle}>
          ACCESS AND CORRECTION OF PERSONAL INFORMATION
        </Text>
        <Text style={styles.text}>
          You can review and change your personal information by logging into the Service and visiting your account
          profile page.
          <br/><br/>

          You may also send us an email at hello@okacam.com to request access to, correct, or delete any personal
          information that you have provided to us. We cannot delete your personal information except by also deleting
          your user account. We may not accommodate a request to change information if we believe the change would
          violate any law or legal requirement or cause the information to be incorrect.
          <br/><br/>
          If you delete your comments or contributions from the Service, copies of your User Content may remain viewable
          in cached and archived pages, or they might have been copied or stored by other Users. Proper access and use
          of information provided on the Service, including User Content, is governed by our Terms.
          <br/><br/>
          You may also contact our Privacy Officer at hello@okacam.com if you wish to obtain further information
          regarding our privacy practices and the way we handle your personal information.
        </Text>
        <Text style={styles.textTitle}>
          OTHER RIGHTS
        </Text>
        <Text style={styles.text}>
          Subject to certain limitations and restrictions (e.g. depending on circumstances such as where you reside) you
          may
          have the right to exercise additional rights in relation to your personal information. These may include the
          right
          to:
          <br/>
          &#x2022; request erasure of your personal information;<br/>
          &#x2022; object to processing of your personal information;<br/>
          &#x2022; request restriction of processing of your personal information;<br/>
          &#x2022; transfer your personal information (i.e. the right to data portability);<br/>
          &#x2022; not be subject to automatic decision making; or<br/>
          &#x2022; withdraw consent.<br/>
          <br/>
          If you wish to exercise or obtain details of any of the rights set out above, please contact our Privacy
          Officer
          at hello@okacam.com. The Privacy Officer may require further information in order to assess the applicability
          of
          the right to the relevant circumstances.
        </Text>
        <Text style={styles.textTitle}>
          INFORMATION RETENTION
        </Text>
        <Text style={styles.text}>
          We will retain your personal information for the period necessary to fulfil the purposes outlined in this
          Privacy
          Policy and in each case in accordance with applicable legal and regulatory requirements in respect of
          permitted or
          required retention periods and limitation periods for taking legal action.
        </Text>
        <Text style={styles.textTitle}>
          ADDITIONAL SERVICES AND LINKS TO OTHER SITES
        </Text>
        <Text style={styles.text}>
          From time to time, we may provide links or mechanics to interface with other websites or mobile applications
          for
          your information or convenience or offer additional services through separate websites or mobile applications
          linked to the Service. These websites and mobile applications operate independently from our Service and may
          be
          subject to alternative terms of use, including terms concerning use of your personal information. We have not
          reviewed these third party sites and do not control and are not responsible for any of these websites, mobile
          applications, their content or their privacy policies. When you submit information or data to third parties,
          their
          privacy policies, not ours, will govern their use of your information and data. You should review the privacy
          policies and terms of service of all such third parties. We do not make any representations regarding the
          policies
          or practices of those third parties. If you decide to access or interact with any of the third party sites
          listed
          on, or linked to, our Service, you do so at your own risk.
        </Text>
        <Text style={styles.textTitle}>
          COMPLAINTS
        </Text>
        <Text style={styles.text}>
          We take your privacy concerns seriously. If you have a complaint regarding our handling of your personal
          information or concerning our privacy practices, you may file a complaint with our Privacy Officer at
          hello@okacam.com. Our Privacy Officer will confirm receipt of your complaint. If our Privacy Officer believes
          an
          investigation is necessary, we will open an investigation into your complaint. Our Privacy Officer may need to
          contact you to request further details of your complaint. If an investigation has been opened following a
          complaint made by you, our Privacy Officer will contact you with the result of that complaint as soon as
          possible.
          In the unlikely circumstances we are unable to resolve your complaint to your satisfaction, you may be able to
          may
          contact the local privacy and data protection authorities.
        </Text>
        <Text style={styles.textTitle}>
          CONTACT US
        </Text>
        <Text style={styles.text}>
          If you have any questions about this Privacy Policy, our privacy practices or if you would like to request
          access
          to or correction of any personal information we may hold about you, please contact our Privacy Officer:
          <br/><br/>
          <b>Email:</b> hello@okacam.com
          <br/><br/>
          <b>Address:</b> BRATTE LTD, Krinou 3, Oval, Office 902, Limassol, Agios Athanasios, 4103, Cyprus
        </Text>
        <View style={{height: 200}}/>
      </>
    );
  }
}
