import React from 'react';
import {StyleSheet, Text} from 'react-native';

const styles = StyleSheet.create({
  textLink: {fontSize: 15, marginTop: 20, color: "#0000ff"},
});

export function Link(props) {
  return (
    <Text
      {...props}
      accessibilityRole="link"
      style={StyleSheet.compose(styles.textLink, props.style)}
    />
  );
}
