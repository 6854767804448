import React from 'react';
import {StyleSheet, Text, View} from 'react-native';
import {Link} from "../utils";

const styles = StyleSheet.create({
  textH1: {fontSize: 40},
  textH3: {fontSize: 20, fontWeight: 'bold'},
  textTitle: {fontSize: 20, fontWeight: 'bold', marginTop: 40},
  text: {fontSize: 15, marginTop: 20, alignSelf: "flex-start"},
  textBold: {fontWeight: 'bold'}
});

export default class TermsPage extends React.Component {
  render() {
    return (
      <>
        <View style={{flex: 1, marginTop: 50, flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
          <Text style={[styles.textH1, styles.textBold]}>Oka Camera Terms of use</Text>
          <Text style={[styles.textBold, {marginTop: 5}]}>effective date: Oct 30, 2021</Text>
        </View>
        <Text style={styles.textTitle}>
          Introduction and Acceptance
        </Text>
        <Text style={styles.text}>
          Bratte Ltd (“Oka Camera”, “we”, “us” and “our”) offers you access to its online websites, mobile applications
          and services. These Terms of Use, and any additional terms which might apply to certain products or services,
          govern your use of our websites (“Website”) or any of our widgets or other applications (“Applications”) –
          together, our “Services”. Our Services include https://okacamera.app/ and all other locations on which we
          place
          these Terms of Use. PLEASE READ THESE TERMS OF USE CAREFULLY BEFORE USING OUR SERVICES. BY USING ANY OF OUR
          SERVICES (OTHER THAN TO READ THESE TERMS OF USE FOR THE FIRST TIME) YOU ARE AGREEING TO COMPLY WITH THESE
          TERMS OF USE, WHICH MAY CHANGE FROM TIME TO TIME AS SET FORTH IN SECTION TITLED “AMENDMENTS; ADDITIONAL
          TERMS”. IF YOU DO NOT AGREE TO BE BOUND BY THESE TERMS OF USE, DO NOT USE OUR SERVICES.
        </Text>
        <Text style={styles.textTitle}>
          Intellectual Property
        </Text>
        <Text style={styles.text}>
          The App offers a smart camera technology to help people shoot better photos and videos. In these App Terms,
          anyone who uses the App is considered a “User”. The App shows hints and suggestions
          how User can try to change composition, scene lighting, selfie position and other photo attributes.
        </Text>
        <Text style={styles.text}>
          Our Services and associated content (and any derivative works or enhancements of the same) including, but not
          limited to, all artwork, text, illustrations, files, images, software, scripts, graphics, photos, sounds,
          music, videos, information, content, materials, products, services, URLs, technology, documentation, and
          interactive features included with or available through our Services (collectively, the “Service Content”) and
          all intellectual property rights to the same are owned by us, our licensors, or both. Additionally, all
          trademarks, service marks, trade names and trade dress that may appear in our Services are owned by us, our
          licensors, or identified third parties. Except for the limited use rights granted to you in these Terms of
          Use, you shall not acquire any right, title or interest in our Services or any Service Content. Any rights not
          expressly granted in these Terms of Use are expressly reserved.
        </Text>
        <Text style={styles.textTitle}>
          Access and Use
        </Text>
        <Text style={styles.text}>
          A. Our Services are provided for your personal, non-commercial use only. We may offer certain portions of our
          Services at no charge and others for a one-time fee, on a subscription basis or under any other lawful pricing
          structure. In all instances, our Services are not being sold to you; rather, you are being granted or
          purchasing a limited license to use our Services. In addition, unless we specifically tell you otherwise, the
          use of any of our paid Services does not transfer across operating systems and/or different equipment (e.g.,
          mobile devices, computers, etc.). For example, unless we specifically tell you otherwise, the use of our
          mobile Application is limited to the relevant device and/or operating system you are using at the time you
          purchase a license to use the Application
        </Text>
        <Text style={styles.text}>
          B. When using our Services, you agree to comply with all applicable federal, state, and local laws including,
          without limitation, copyright law. Except as expressly permitted in these Terms of Use or as we may otherwise
          permit, you may not use, reproduce, duplicate, distribute, create derivative works based upon, publicly
          display, publicly perform, publish, transmit, or otherwise exploit Service Content for any purpose whatsoever
          without obtaining prior written consent from us or, in the case of third party content, its applicable owner.
          In certain instances, we may suggest, ask or otherwise permit you to download, install or print Service
          Content. In such a case, you may do so only in the manner authorized and for your non-commercial use only. You
          acknowledge that you do not acquire any ownership rights by downloading, installing or printing Service
          Content.
        </Text>
        <Text style={styles.text}>
          C. Furthermore, except as expressly permitted in these Terms of Use, you may not: (i) remove, alter, cover, or
          distort any copyright, trademark, or other proprietary rights notice we include in or through our Services or
          Service Content; (ii) circumvent, disable or otherwise interfere with our security-related features including,
          without limitation, any features that prevent or restrict the use of or copying of any software or other
          Service Content; (iii) use an automatic device (such as a robot or spider) or manual process to copy or
          “scrape” the Website or Service Content for any purpose without our express written permission; (iv) collect
          or harvest any personally identifiable information from our Services including, without limitation, user
          names, passwords, email addresses; (v) solicit other users to join or become members of any commercial online
          service or other organization without our prior written approval; (vi) attempt to or interfere with the proper
          working of our Services or impair, overburden, or disable the same; (vii) decompile, reverse engineer, or
          disassemble any portion of our software or other Service Content, or our Services; (viii) use
          network-monitoring software to determine architecture of or extract usage data from our Services; (ix)
          encourage conduct that violates any local, state or federal law, either civil or criminal, or impersonate
          another user, person, or entity (e.g., using another person’s Membership (as defined below)); (x) violate U.S.
          export laws, including, without limitation, violations of the Export Administration Act and the Export
          Administration Regulations administered by the Department of Commerce; or (xi) engage in any conduct that
          restricts or inhibits any other user from using or enjoying our Services.
        </Text>
        <Text style={styles.text}>
          D. You agree to fully cooperate with us to investigate any suspected or actual activity that is in breach of
          these Terms of Use.
        </Text>
        <Text style={styles.textTitle}>
          Memberships and Subscriptions
        </Text>
        <Text style={styles.text}>
          A. General Membership Terms.<br/>
          In the course of your use of the Services, you may be asked to provide certain personalized information to us
          (such information is referred to hereinafter as “User Information”). Our information collection and use
          policies with respect to the privacy of such User Information are set forth in the Oka Camera Privacy Policy.
          You
          acknowledge and agree that you are solely responsible for the accuracy and content of User Information, and
          you agree to keep it up to date.
          <br/><br/>
          By registering for the Services, you warrant that: (i) you are legally capable of entering into binding
          contracts; (ii) all registration information you submit is truthful and accurate; (iii) you will maintain the
          accuracy of such information; and (iv) your use of the Services does not violate any applicable law or
          regulation.
          <br/><br/>
          You are responsible for maintaining the confidentiality of your account, password, and other User Information
          and for restricting access to your device to further help protect such information. You are responsible for
          updating your User Information.
          <br/><br/>
          Oka Camera Memberships and subscriptions are not transferable and therefore you agree to not sell, transfer,
          or exchange Memberships or subscriptions in any way or under any circumstance. This expressly applies to any
          discounted, subsidized, or free accounts or subscriptions.
          <br/><br/>
          You may not register for more than one Membership.
        </Text>
        <Text style={styles.text}>
          B. Subscriptions.<br/>
          Oka Camera account holders may access the Services in two ways:
          <br/><br/>
          Unregistered Free Use
          <br/><br/>
          You may use our Services free of charge. You may, but are not required to, provide us with your name and email
          address in connection with such use. Notably, the Services will be limited for free use, and you will not have
          access to all of the content available to paid users.
          <br/><br/>
          Paid Subscription (“Oka Camera Pro”)
          <br/><br/>
          Oka Camera also offers a subscription fee-based program, which gives access to all content. Once you register
          for the paid subscription, you will be considered a Member of Oka Camera Pro. You will only have access to Oka
          Camera Pro while your subscription is active and subsisting. You may have access to a free trial period of Oka
          Camera Pro in accordance with certain promotional offers.
          <br/><br/>
          You may access Oka Camera Pro by purchasing a subscription to the Services from: (a) the Website, (b) within
          the App, or (c) where allowed by the App marketplace partners.
          <br/><br/>
          Subscriptions to Oka Camera Pro may either by monthly or annual. For the purposes of our monthly and yearly
          subscriptions, a month constitutes 30 calendar days, and a year constitutes 365 calendar days.
        </Text>
        <Text style={styles.text}>
          Monthly Subscriptions
          <br/><br/>
          Our “Monthly” subscription is paid in monthly installments. For each month that your monthly subscription is
          active, you acknowledge and agree that Oka Camera is authorized to charge the same credit card as was used for
          the
          initial subscription fee or other payment method as set forth the Payment Method section below (the “Payment
          Method”). The monthly renewal subscription fees will continue to be billed to the Payment Method you provided
          until cancelled. You must cancel your subscription before it renews to avoid billing of the next month’s
          subscription fee to the Payment Method you provided. Refunds cannot be claimed for any partial-month
          subscription period.
          <br/><br/>
          Yearly Subscriptions
          <br/><br/>
          Our “Yearly” subscription is paid for by an upfront payment with automatic annual renewals. You acknowledge
          and agree that Oka Camera is authorized to charge the Payment Method used for (i) the initial annual
          subscription
          fee at the rate secured at the time of purchase, and (ii) the non-discounted renewal subscription fee(s). You
          must cancel your subscription before it renews to avoid billing of the renewal subscription fee to the Payment
          Method you provided. Refunds cannot be claimed for any partial subscription period.
          <br/><br/>
          Refunds
          <br/><br/>
          Please note that if you purchase a subscription through the Apple iTunes Store or Google Play, the sale is
          final, and we cannot provide a refund. Your purchase will be subject to Apple’s and/or Google’s applicable
          payment policy, which may or may not allow for refunds.
          <br/><br/>
          If you purchase through our Website, we will provide a one-time only refund of your monthly subscription or
          your initial yearly subscription if you email hello@okacam.com within thirty (30) days of your purchase. No
          refunds will apply to requests made outside the foregoing time frame or for subsequent renewals of the yearly
          subscription. In addition, we must receive notice of your intent not to renew prior to the renewal term, or
          the subscription will renew and no refund will be available.
          <br/><br/>
          Changes in Pricing
          <br/><br/>
          We reserve the right to change our subscription plans or adjust pricing for our service or any components
          thereof in any manner and at any time as we may determine in our sole and absolute discretion. Except as
          otherwise expressly provided for in these Terms, any price changes or changes to your subscription plan will
          take effect following notice to you.
        </Text>
        <Text style={styles.text}>
          C. Payment Method.<br/>
          In the course of your use of the Services, Oka Camera and its third-party payment service provider may receive
          and
          implement updated credit card information from your credit card issuer to prevent your subscription from being
          interrupted by an outdated or invalid card. This disbursement of the updated credit card information is
          provided to Oka Camera and Oka Camera’s third party payment service provider at the sole election of your
          credit card
          issuer. Your credit card issuer may give you the right to opt-out of the update service. Should you desire to
          do so, please contact your credit card issuer.
          <br/><br/>
          You agree to promptly notify Oka Camera of any changes to the Payment Method you provided while any
          subscriptions
          remain outstanding. You are responsible for all applicable fees and charges incurred, including applicable
          taxes, and all subscriptions purchased by you.
          <br/><br/>
          If payment is not received by us from the Payment Method you provided, you agree to pay all amounts due upon
          demand by us.
        </Text>
        <Text style={styles.text}>
          D. Cancelation of Services.<br/>
          Subject to the refund policy set forth above, you may cancel a monthly subscription at any time. Cancellation
          is effective at the end of the applicable monthly period. Please make any such cancellation by emailing
          hello@okacam.com.
          <br/><br/>
          Subject to the refund policy, you may cancel a yearly subscription plan at any time. Cancellation is effective
          at the end of the applicable annual period.
          <br/><br/>
          Please note that if you purchase a subscription through the Apple iTunes Store or on Google Play, you may
          cancel your subscription in accordance with the policies set forth by Apple and Google.
          <br/><br/>
          We may suspend or terminate your use of the Services as a result of your fraud or breach of any obligation
          under these Terms. Such termination or suspension may be immediate and without notice. A breach of these Terms
          includes without limitation, the unauthorized copying or download of our audio or video content from the
          Services.
        </Text>
        <Text style={styles.text}>
          E. Promotion and Discount Codes.<br/>
          Any promotion code or offer provided by us may not be used in conjunction with any other promotion code or
          offer, past or present. Introductory offers are only available to new users of the Services, except where
          expressly stated otherwise. Previous users or trial users of the Services do not qualify as new users. Unless
          otherwise set forth in the terms of any promotion, all pricing promotions or discounts will apply to the
          initial period of the subscription, and any renewals will be charged at the non-discounted rate for the type
          of subscription purchased.
        </Text>
        <Text style={styles.textTitle}>
          Service Content & Third Party Links
        </Text>
        <Text style={styles.text}>
          A. We provide our Services including, without limitation, Service Content for educational, entertainment
          and/or promotional purposes only. You may not rely on any information and opinions expressed through any of
          our Services for any other purpose. In all instances, it is your responsibility to evaluate the accuracy,
          timeliness, completeness, or usefulness of any Service Content. Under no circumstances will we be liable for
          any loss or damage caused by your reliance on any Service Content.
        </Text>
        <Text style={styles.text}>
          B. In many instances, Service Content will include content posted by a third-party or will represent the
          opinions and judgments of a third-party. We do not endorse, warrant and are not responsible for the accuracy,
          timeliness, completeness, or reliability of any opinion, advice, or statement offered through our Services by
          anyone other than our authorized employees or spokespersons while acting in their official capacities
        </Text>
        <Text style={styles.text}>
          C. Our Services may link or contain links to other websites maintained by third parties. We do not operate or
          control, in any respect, or necessarily endorse the content found on these third-party websites. You assume
          sole responsibility for your use of third-party links. We are not responsible for any content posted on
          third-party websites or liable to you for any loss or damage of any sort incurred as a result of your dealings
          with any third-party or their website.
        </Text>
        <Text style={styles.textTitle}>
          Indemnification
        </Text>
        <Text style={styles.text}>
          You agree to indemnify and hold harmless Bratte Ltd and its officers, directors, employees, parents, partners,
          successors, agents, distribution partners, affiliates, subsidiaries, and their related companies from and
          against any and all claims, liabilities, losses, damages, obligations, costs and expenses (including
          reasonable attorneys’ fees and costs) arising out of, related to, or that may arise in connection with: (i)
          your use of our Services; (ii) User Content provided by you or through use of your Membership; (iii) any
          actual or alleged violation or breach by you of these Terms of Use; (iv) any actual or alleged breach of any
          representation, warranty, or covenant that you have made to us; or (v) your acts or omissions. You agree to
          cooperate fully with us in the defense of any claim that is the subject of your obligations hereunder.
        </Text>
        <Text style={styles.textTitle}>
          Disclaimer of Warranties
        </Text>
        <Text style={styles.text}>
          A. YOU EXPRESSLY AGREE THAT USE OF OUR SERVICES IS AT YOUR SOLE RISK. OUR SERVICES AND SERVICE CONTENT
          (INCLUDING SOFTWARE) ARE PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS WITHOUT WARRANTY OF ANY KIND, EITHER
          EXPRESS OR IMPLIED. WITHOUT LIMITING THE FOREGOING AND TO THE FULLEST EXTENT PERMITTED BY LAW, BRATTE LTD AND
          ITS OFFICERS, DIRECTORS, EMPLOYEES, PARENTS, PARTNERS, SUCCESSORS, AGENTS, DISTRIBUTION PARTNERS, AFFILIATES,
          SUBSIDIARIES, AND THEIR RELATED COMPANIES DISCLAIM ANY AND ALL WARRANTIES INCLUDING ANY: (1) WARRANTIES THAT
          OUR SERVICES WILL MEET YOUR REQUIREMENTS; (2) WARRANTIES CONCERNING THE AVAILABILITY, ACCURACY, SECURITY,
          USEFULNESS, TIMELINESS, OR INFORMATIONAL CONTENT OF OUR SERVICES OR SERVICE CONTENT; (3) WARRANTIES OF TITLE,
          NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE; (4) WARRANTIES FOR SERVICES OR GOODS
          RECEIVED THROUGH OR ADVERTISED OR ACCESSED THROUGH OUR SERVICES; (5) WARRANTIES CONCERNING THE ACCURACY OR
          RELIABILITY OF THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF OUR SERVICES; (6) WARRANTIES THAT YOUR USE OF
          OUR SERVICES WILL BE SECURE OR UNINTERRUPTED; AND (7) WARRANTIES THAT ERRORS IN OUR SERVICES OR SERVICE
          CONTENT (INCLUDING SOFTWARE) WILL BE CORRECTED.
        </Text>
        <Text style={styles.text}>
          Limitation on Liability
        </Text>
        <Text style={styles.text}>
          A. UNDER NO CIRCUMSTANCES SHALL BRATTE LTD OR ITS OFFICERS, DIRECTORS, EMPLOYEES, PARENTS, PARTNERS,
          SUCCESSORS, AGENTS, DISTRIBUTION PARTNERS, AFFILIATES, SUBSIDIARIES, OR THEIR RELATED COMPANIES BE LIABLE FOR
          INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES (EVEN IF WE HAVE BEEN ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES), ARISING OUT OF, RELATING TO, OR IN ANY WAY CONNECTED WITH OUR SERVICES OR THESE
          TERMS OF USE. YOUR SOLE REMEDY FOR DISSATISFACTION WITH OUR SERVICES INCLUDING, WITHOUT LIMITATION, SERVICE
          CONTENT IS TO STOP USING OUR SERVICES. SUCH LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES INCURRED BY
          REASON OF GOODS RECEIVED THROUGH OR ADVERTISED IN CONNECTION WITH OUR SERVICES OR ANY LINKS PLACED IN OUR
          SERVICES, AS WELL AS BY REASON OF ANY INFORMATION OR ADVICE RECEIVED THROUGH OR ADVERTISED IN CONNECTION WITH
          OUR SERVICES OR ANY LINKS PLACED IN OUR SERVICES. SUCH LIMITATION SHALL ALSO APPLY WITH RESPECT TO DAMAGES
          INCURRED BY REASON OF ANY CONTENT POSTED BY A THIRD PARTY OR CONDUCT OF A THIRD PARTY USING OUR SERVICES.
        </Text>
        <Text style={styles.text}>
          B. NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN, IN NO EVENT SHALL THE CUMULATIVE LIABILITY OF
          BRATTE LTD AND ITS OFFICERS, DIRECTORS, EMPLOYEES, PARENTS, PARTNERS, SUCCESSORS, AGENTS, DISTRIBUTION
          PARTNERS, AFFILIATES, SUBSIDIARIES, AND THEIR RELATED COMPANIES EXCEED THE LESSER OF THE TOTAL PAYMENTS
          RECEIVED FROM YOU BY BRATTE LTD DURING THE PRECEDING TWELVE (12) MONTH PERIOD OR $100. FURTHERMORE, YOU AGREE
          THAT ANY CAUSE OF ACTION ARISING OUT OF, RELATING TO, OR IN ANY WAY CONNECTED WITH ANY OF OUR SERVICES OR
          THESE TERMS OF USE MUST COMMENCE WITHIN ONE (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES; OTHERWISE, SUCH CAUSE
          OF ACTION SHALL BE PERMANENTLY BARRED.
        </Text>
        <Text style={styles.text}>
          C. In some jurisdictions limitations of liability are not permitted. In such jurisdictions, some of the
          foregoing limitations may not apply to you. These limitations shall apply to the fullest extent permitted by
          law.
        </Text>
        <Text style={styles.textTitle}>
          Termination
        </Text>
        <Text style={styles.text}>
          A. We reserve the right in our sole discretion and at any time to terminate or suspend your Membership and/or
          block your use of our Services for any reason including, without limitation if you have failed to comply with
          the letter and spirit of these Terms of Use. You agree that Bratte Ltd is not liable to you or any third party
          for any termination or suspension of your Membership or for blocking your use of our Services.
        </Text>
        <Text style={styles.text}>
          B. Any suspension or termination shall not affect your obligations to us under these Terms of Use. The
          provisions of these Terms of Use which by their nature should survive the suspension or termination of your
          Membership or these Terms of Use shall survive including, but not limited to the rights and licenses that you
          have granted hereunder, indemnities, releases, disclaimers, limitations on liability, provisions related to
          choice of law, and all of the provisions in the Section titled “MISCELLANEOUS”.
        </Text>
        <Text style={styles.textTitle}>
          Copyright Policy
        </Text>
        <Text style={styles.text}>
          A. We respect the intellectual property rights of others and expect users to do the same. In appropriate
          circumstances and at our sole discretion, we may terminate and/or disable the Membership of users suspected to
          be infringing the copyrights (or other intellectual property rights) of others. Additionally, in appropriate
          circumstances and in our sole discretion, we may remove or disable access to material on any of our websites
          or hosted on our systems that may be infringing or the subject of infringing activity.
        </Text>
        <Text style={styles.text}>
          B. In accordance with the Digital Millennium Copyright Act of 1998, Title 17 of the United States Code,
          Section 512 (“DMCA”), we will respond promptly to claims of copyright infringement that are reported to the
          agent that we have designated to receive notifications of claims infringement (its “Designated Agent”). Our
          Designated Agent is: Northwest Registered Agent LLC with address 8 The Green, Ste B, Dover, DE, 19901, USA. If
          you are the copyright owner (or authorized to act on behalf of the copyright owner) and believe that your
          work’s copyright has been infringed, please report your notice of infringement to us by providing our
          Designated Agent with a written notification of claimed infringement that includes substantially the
          following: (i) A physical or electronic signature of a person authorized to act on behalf of the owner of an
          exclusive right that is allegedly infringed. (ii) Identification of the copyrighted work claimed to have been
          infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a
          representative list of such works at that site. (iii) Identification of the material that is claimed to be
          infringing or to be the subject of infringing activity and that is to be removed or access to which is to be
          disabled, and information reasonably sufficient to permit us to locate the material. (iv) Information
          reasonably sufficient to permit us to contact you, such as an address, telephone number, and, if available, an
          electronic mail address at which you may be contacted. (v) A statement that you have a good faith belief that
          use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the
          law. (vi) A statement that the information in the notice is accurate, and under penalty of perjury, that you
          are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. We will
          investigate notices of copyright infringement and take appropriate actions under the DMCA. Inquiries that do
          not follow this procedure may not receive a response.
        </Text>
        <Text style={styles.textTitle}>
          Choice of Law; Jurisdiction and Venue
        </Text>
        <Text style={styles.text}>
          These Terms of Use shall be construed in accordance with the laws of Scotland without regard to its conflict
          of laws rules. Any legal proceedings against Bratte Ltd that may arise out of, relate to, or be in any way
          connected with our Website or these Terms of Use shall be brought exclusively in the state and federal courts
          of Scotland and you waive any jurisdictional, venue, or inconvenient forum objections to such courts.
        </Text>
        <Text style={styles.textTitle}>
          Dispute Resolution & Mandatory Arbitration
        </Text>
        <Text style={styles.text}>
          A. We each agree to first contact each other with any disputes and provide a written description of the
          problem, all relevant documents/information and the proposed resolution. You agree to contact us with disputes
          by contacting us at the address provided in these Terms of Use. We will contact you based on the contact
          information you have provided us.
        </Text>
        <Text style={styles.text}>
          B. If after 30 days the parties are unable to resolve any dispute raised under the previous provision, the
          dispute may be submitted to arbitration consistent with this Section. The parties understand that they would
          have had a right or opportunity to litigate disputes through a court and to have a judge or jury decide their
          case, but they choose to have any disputes resolved through arbitration.
        </Text>
        <Text style={styles.text}>
          C. We each agree that any claim or dispute between us, and any claim by either of us against any agent,
          employee, successor, or assign of the other, including, to the full extent permitted by applicable law, third
          parties who are not signatories to this agreement, whether related to this agreement or otherwise, including
          past, present, and future claims and disputes, and including any dispute as to the validity or applicability
          of this arbitration clause, shall be resolved by binding arbitration administered by the JAMS under its rules
          and procedures in effect when the claim is filed. The rules and procedures and other information, including
          information on fees, may be obtained from JAMS’ website (www.jamsadr.com) or by calling JAMS at 949-224-1810.
        </Text>
        <Text style={styles.text}>
          D. We are entering into this arbitration agreement in connection with a transaction involving interstate
          commerce. Accordingly, this arbitration agreement and any proceedings thereunder shall be governed by the
          Federal Arbitration Act (“FAA”), 9 U.S.C. §§ 1-16. Any award by the arbitrator(s) may be entered as a judgment
          in any court having jurisdiction.
        </Text>
        <Text style={styles.text}>
          E. Exception to Arbitrate. Either of us may bring qualifying claims in small claims court. Further, as set
          forth below, we each agree that any arbitration will be solely between you and Bratte Ltd, not as part of a
          classwide claim (i.e., not brought on behalf of or together with another individual’s claim). If for any
          reason any court or arbitrator holds that this restriction is unconscionable or unenforceable, then our
          agreement to arbitrate doesn’t apply and the classwide dispute must be brought in court.
        </Text>
        <Text style={styles.text}>
          No Class Actions
        </Text>
        <Text style={styles.text}>
          TO THE EXTENT ALLOWED BY LAW, WE EACH WAIVE ANY RIGHT TO PURSUE DISPUTES ON A CLASSWIDE BASIS; THAT IS, TO
          EITHER JOIN A CLAIM WITH THE CLAIM OF ANY OTHER PERSON OR ENTITY, OR ASSERT A CLAIM IN A REPRESENTATIVE
          CAPACITY ON BEHALF OF ANYONE ELSE IN ANY LAWSUIT, ARBITRATION OR OTHER PROCEEDING.
        </Text>
        <Text style={styles.text}>
          No Trial by Jury
        </Text>
        <Text style={styles.text}>
          TO THE EXTENT ALLOWED BY LAW, WE EACH WAIVE ANY RIGHT TO TRIAL BY JURY IN ANY LAWSUIT, ARBITRATION OR OTHER
          PROCEEDING.
        </Text>
        <Text style={styles.textTitle}>
          Amendment; Additional Terms
        </Text>
        <Text style={styles.text}>
          A. We reserve the right in our sole discretion and at any time and for any reason, to modify or discontinue
          any aspect or feature of our Services or to modify these Terms of Use. In addition, we reserve the right to
          provide you with operating rules or additional terms that may govern your use of our Services generally,
          unique of our Services, or both (“Additional Terms”). Any Additional Terms that we may provide to you will be
          incorporated by reference into these Terms of Use. To the extent any Additional Terms conflict with these
          Terms of Use, the Additional Terms will control.
        </Text>
        <Text style={styles.text}>
          B. Modifications to these Terms of Use or Additional Terms will be effective immediately upon notice, either
          by posting on the Website, notification by email or through any of our Applications. It is your responsibility
          to review the Terms of Use from time to time for any changes or Additional Terms. Your access and use of our
          Services following any modification of these Terms of Use or the provision of Additional Terms will signify
          your assent to and acceptance of the same. If you object to any subsequent revision to the Terms of Use or to
          any Additional Terms, immediately discontinue use of our Services and, if applicable, terminate your
          Membership
        </Text>
        <Text style={styles.textTitle}>
          Miscellaneous
        </Text>
        <Text style={styles.text}>
          A. No waiver by either party of any breach or default hereunder shall be deemed to be a waiver of any
          preceding or subsequent breach or default. The section headings used herein are for convenience only and shall
          not be given any legal import.
        </Text>
        <Text style={styles.text}>
          B. Except where specifically stated otherwise, if any part of these Terms of Use is unlawful or unenforceable
          for any reason, we both agree that only that part of the Terms of Use shall be stricken and that the remaining
          terms in the Terms of Use shall not be affected.
        </Text>
        <Text style={styles.text}>
          C. These Terms of Use constitute the entire agreement of the parties with respect to the subject matter
          hereof, and supersede all previous written or oral agreements between us with respect to such subject matter.
        </Text>
        <Text style={styles.text}>
          D. You may not assign these Terms of Use or assign any rights or delegate any obligations hereunder, in whole
          or in part, without our prior written consent. Any such purported assignment or delegation by you without the
          appropriate prior written consent will be null and void and of no force and effect. We may assign these Terms
          of Use or any rights hereunder without your consent and without notice.
        </Text>
        <Text style={styles.textTitle}>
          Contact Information
        </Text>
        <Text style={styles.text}>
          <b>Email:</b> hello@okacam.com
          <br/><br/>
          <b>Address:</b> BRATTE LTD, Krinou 3, Oval, Office 902, Limassol, Agios Athanasios, 4103, Cyprus
        </Text>
        <View style={{height: 200}}/>
      </>
    );
  }
}
