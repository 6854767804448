import React from 'react';
import {AppRegistry, View, Text} from 'react-native';
import PrivacyPage from "./privacy";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import TermsPage from "./terms";
import MainPage from "./main";

class App extends React.Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/" exact component={() => <MainPage/>}/>
          <Route path="/privacy" exact component={() =>
            <View style={{alignItems: "center", marginHorizontal: "5%"}}>
              <PrivacyPage/>
            </View>}/>
          <Route path="/terms" exact component={() =>
            <View style={{alignItems: "center", marginHorizontal: "5%"}}>
              <TermsPage/>
            </View>}/>
          {/*<Route path="/ccpa" exact component={() => <CCPAPage/>}/>*/}
        </Switch>
      </Router>
    );
  }
}

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', {rootTag: document.getElementById('react-root')});
