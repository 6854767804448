import React from 'react';
import {Image, Dimensions, View} from 'react-native';
import {Text} from "react-native-web";

export default function MainPage() {
  const [windowDims, setWindow] = React.useState(Dimensions.get('window'));

  React.useEffect(() => {
    const handleChange = ({screen, window: win}) => {
      setWindow(win);
    };

    const subscription = Dimensions.addEventListener('change', handleChange);
    return () => {
      subscription.remove();
    };
  }, [setWindow]);

  return (
    <View style={{
      width: windowDims.width,
      height: windowDims.height,
      backgroundColor: "#000000",
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <View style={{flex:1}}/>
      <Image source={"app-logo.png"} style={{
        width: 150,
        height: 150
      }}/>
      <View style={{flex:1, flexDirection:"column", alignItems:"center"}}>
        <Text style={{
          color:"#ffffff",
        }}>hello@okacam.com</Text>
      </View>
    </View>
  );
}
